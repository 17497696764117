import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { getBreadcrumbs } from './components/HeadlineBreadcrumb/breadcrumbs.js';
import { HeadlineBreadcrumb } from './components/HeadlineBreadcrumb';
import HeadlineTimestamp from './components/HeadlineTimestamp/index.jsx';
import HeadlineByline from './components/HeadlineByline/index.jsx';
import HeadlineComments from './components/HeadlineComments';
import { checkAdDisclaimer } from './components/HeadlineByline/bylines.js';
import HeadlineBadges, { Badge } from './components/HeadlineBadges/index.jsx';
import styles from './headline.module.scss';
import { withTranslation } from '../../i18n';
import { withErrorBoundary } from '@buzzfeed/react-components';
import { captureException } from '@sentry/nextjs';
import DestinationContext from '../../contexts/destination';
import { isNews } from '../../utils/isNews.js';
import CommerceDisclaimer from '../CommerceDisclaimer';
import SubscriptionButton from '@buzzfeed/react-components/lib/components/SubscriptionButton';
import { site_captcha_key } from '../../constants';
import { getUserCookieInfo } from '../../utils/userInfo';
import { useSubscriptionButtonTracking } from '../../hooks/analytics/useSubscriptionButtonTracking';

export function Headline({ buzz, badges, t }) {
  const { destination } = useContext(DestinationContext);
  const { isCommentsPage } = buzz;
  const timestampPublished = Number(buzz.published);
  const timestampUpdated =
    'edit_updated' in buzz ? Number(buzz.edit_updated) : 0;
  const showUpdatedTimestampOnly = buzz.tags.includes(
    '--updated-timestamp-only'
  );
  const isEnglish = buzz.language === 'en';
  let subscriptionOffering = null;
  const hasTagName = (targetTag) => {
    const someTagMatch = (tag) => tag.tag_name === targetTag;
    return buzz?.laser_tags?.bf_content_description?.topic?.some(someTagMatch);
  };
  if (buzz.isAd || (buzz.isCommunity && !buzz.isModerated)) { // Hide on branded or unmoderated community pages
    subscriptionOffering = null;
  } else if (hasTagName('taylor_swift') && isEnglish) {
    subscriptionOffering = 'taylor_swift';
  } else if (hasTagName('arcade') && isEnglish) {
    subscriptionOffering = 'arcade';
  } else if (buzz.isShopping && isEnglish) {
    subscriptionOffering = 'shopping';
  } else if (buzz.isQuiz && isEnglish) {
    subscriptionOffering = 'quizzes';
  } else if (isEnglish) {
    subscriptionOffering = 'daily';
  }

  const isAd = buzz.flags.ad === 1;
  const countryCode = buzz.country_code;
  const { primary, secondary } = getBreadcrumbs(buzz, badges);
  const hasAdDisclaimer =
    checkAdDisclaimer(buzz.bylines) && buzz.classification.edition !== 'Japan';

  // If it's not BFN post, the longform custom header will override the title and description set on the post.
  const { title, description } = buzz.longform_custom_header && !isNews(buzz)
    ? buzz.longform_custom_header
    : buzz;
  const showBreadcrumb = !isNews(buzz) || (isNews(buzz) && (primary.length || secondary.length) > 0);

  const subscriptionButtonTrackingData = {
    unit_type: 'buzz_head',
    unit_name: buzz.id,
    item_type: 'button',
  };

  const trackSubscriptionButton = useSubscriptionButtonTracking(
    buzz,
    subscriptionButtonTrackingData
  );

  // The longform custom header will display the <h1> overlaid on the image if configured with `text_over`
  // We use CSS to visually hide the title but keep it in the DOM as an a11y landmark
  // Visual title will be added in FeaturedImage/index.jsx
  const hasImageText =
    buzz.longform_custom_header &&
    buzz.longform_custom_header.style &&
    buzz.longform_custom_header.style.text_over;

  if (buzz.hasQuizSharedResult) {
    const quizBadge = badges[0];
    quizBadge.has_svg = true;
    quizBadge.badge_language = 'general';

    return (
      <div className={styles.sharedResultHeadlineWrapper}>
        <div className={styles.sharedResultBadge}>
          <Badge index={0} badge={quizBadge} buzzId={buzz.buzzId} />
          <p>Result from</p>
        </div>
        <h1 className={styles.sharedResultTitle}>
          <a href={buzz.canonical_url}>{buzz.title}</a>
        </h1>
      </div>
    );
  }

  const breadcrumb = (
    <div className={`${styles.container} embed-breadcrumbs`}>
    {isAd ? (
      <span className={styles.paidPostBreadcrumb}>{t('paid_post')}</span>
    ) : (
      <nav
        aria-label="Breadcrumb"
        className={ !isNews(buzz) ? styles.breadcrumbContainer : styles.breadcrumbContainerNews }
      >
        <HeadlineBreadcrumb
          breadcrumbs={primary}
          secondaryBreadcrumbs={secondary}
        />
        {destination === 'buzzfeed' &&
          primary.length !== 0 &&
          timestampPublished > 0 && (
            <span className={`bold ${styles.middot}`}>&#183;</span>
          )}
      </nav>
    )}
    {destination === 'buzzfeed' && (
      <HeadlineTimestamp
        timestampPublishedUnix={timestampPublished}
        timestampUpdatedUnix={timestampUpdated}
        countryCode={countryCode}
        destination={destination}
      />
    )}
  </div>);

  const shouldDisplay = {
    adDisclaimer: !isCommentsPage && hasAdDisclaimer,
    badges: !isCommentsPage && !buzz.isAd && destination === 'buzzfeed',
    breadcrumbAboveTitle: !isCommentsPage && showBreadcrumb,
    breadcrumbBelowTitle: isCommentsPage && showBreadcrumb,
    byline: !isCommentsPage,
    commerceDisclaimer: !isCommentsPage,
    communityDisclaimer: !isCommentsPage && !buzz.isModerated,
    timestamp: !isCommentsPage && destination === 'buzzfeed_news',
  };

  return (
    <div
      className={`${styles.headline} ${styles[buzz.bfpTheme] || ''} ${
        styles[destination]
      } embed-buzz-header`}
    >
      {shouldDisplay['badges'] && (
        <div className={styles.container}>
          <HeadlineBadges
            badges={badges}
            buzzId={buzz.id}
            countryCode={countryCode}
            flags={buzz.flags}
          />
        </div>
      )}
      {isCommentsPage && <HeadlineComments />}
      {shouldDisplay['commerceDisclaimer'] && <CommerceDisclaimer buzz={buzz} />}
      {shouldDisplay['communityDisclaimer'] && (
        <div className={`${styles.disclaimer} ${styles.container}`}>
          {t('community_disclaimer')}
          <a className="blue-link" href="http://community.buzzfeed.com/">
            {' '}
            {t('community_try')}
          </a>
        </div>
      )}
      {shouldDisplay['adDisclaimer'] && (
        <div className={`${styles.disclaimer} ${styles.container}`}>
          {t('ad_disclaimer')}
        </div>
      )}
      {shouldDisplay['breadcrumbAboveTitle'] && breadcrumb}

      {(subscriptionOffering === 'taylor_swift') && <>
        <SubscriptionButton
          topic="Taylor Swift"
          subscriptions={[
            {
              subscriptionId: 'buzzfeed_email_taylor-swift-weekly-rundown',
              title: 'Weekly Rundown',
              description: 'Our weekly Taylor Swift rundown right to your inbox.',
              isSubscribed: true,
            },
            {
              subscriptionId: 'buzzfeed_email_taylor-swift-daily-rundown',
              title: 'Daily Rundown',
              description: 'Can’t wait a whole week? Get updated on all things Taylor Swift daily.',
              isSubscribed: false,
            },
          ]}
          isSignedIn={!!getUserCookieInfo()}
          siteCaptchaKey={site_captcha_key}
          track={trackSubscriptionButton}
        />
        <br />
      </>}

      {(subscriptionOffering === 'arcade') && <>
      <SubscriptionButton
        topic="Arcade"
        description="Be notified on the launch of new games, AI image generators, filters, and experiments — no more than once a week!"
        descriptionLoggedOut="Be notified on the launch of new games, AI image generators, filters, and experiments — no more than once a week!"
        subscriptions={[
          {
            subscriptionId: 'buzzfeed_email_arcade-highlights-weekly',
            title: 'Arcade Highlights',
            description: '',
            isSubscribed: true,
          },
        ]}
        isSignedIn={!!getUserCookieInfo()}
        siteCaptchaKey={site_captcha_key}
        track={trackSubscriptionButton}
      />
      <br />
    </>}

      {(subscriptionOffering === 'quizzes') && <>
        <SubscriptionButton
          topic="Quizzes"
          description="The quizzes you know and love delivered straight to your inbox!"
          descriptionLoggedOut="The quizzes you know and love delivered straight to your inbox!"
          subscriptions={[
            {
              subscriptionId: 'buzzfeed_email_quizzes',
              title: 'Quizzes',
              description: '',
              isSubscribed: true,
            },
          ]}
          isSignedIn={!!getUserCookieInfo()}
          siteCaptchaKey={site_captcha_key}
          track={trackSubscriptionButton}
        />
        <br />
      </>}

      {(subscriptionOffering === 'shopping') && <>
        <SubscriptionButton
          topic="Shopping"
          description="Find great products - plus cool stuff you didn’t even know you needed."
          descriptionLoggedOut="Find great products - plus cool stuff you didn’t even know you needed."
          subscriptions={[
            {
              subscriptionId: 'buzzfeed_email_shopping',
              title: 'Shopping',
              description: '',
              isSubscribed: true,
            },
          ]}
          isSignedIn={!!getUserCookieInfo()}
          siteCaptchaKey={site_captcha_key}
          track={trackSubscriptionButton}
        />
        <br />
      </>}

      {(subscriptionOffering === 'daily') && <>
        <SubscriptionButton
          topic="BuzzFeed Daily"
          description="Keep up with the latest daily buzz with the BuzzFeed Daily newsletter!"
          descriptionLoggedOut="Keep up with the latest daily buzz with the BuzzFeed Daily newsletter!"
          subscriptions={[
            {
              subscriptionId: 'buzzfeed_email_daily',
              title: 'BuzzFeed Daily',
              description: '',
              isSubscribed: true,
            },
          ]}
          isSignedIn={!!getUserCookieInfo()}
          siteCaptchaKey={site_captcha_key}
          track={trackSubscriptionButton}
        />
        <br />
      </>}

      <div
        className={`${styles.container} ${
          hasImageText ? styles.hideTitle : ''
        } embed-headline`}
      >
        <h1
          className={`${styles.title} embed-headline-title`}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className={`${styles.description} embed-headline-description`}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      {shouldDisplay['breadcrumbBelowTitle'] && breadcrumb}
      {shouldDisplay['byline'] &&
        <HeadlineByline
          bylines={buzz.bylines}
          isAd={isAd}
          buzz={buzz}
        />}
      {shouldDisplay['timestamp'] && (
        <HeadlineTimestamp
          timestampPublishedUnix={timestampPublished}
          timestampUpdatedUnix={timestampUpdated}
          showUpdatedTimestampOnly={showUpdatedTimestampOnly}
          countryCode={countryCode}
          destination={destination}
        />
      )}
    </div>
  );
}

Headline.propTypes = {
  buzz: PropTypes.object.isRequired,
  badges: PropTypes.array,
};

export default withErrorBoundary(withTranslation('common')(Headline), {
  onError: captureException,
});
