import React, { useContext, useState, useEffect } from 'react';
import { withErrorBoundary } from '@buzzfeed/react-components';
import { AdsContext } from '@buzzfeed/adlib/dist/module/bindings/react/contexts';
import { captureException } from '@sentry/nextjs';
import BuzzContext from '../../contexts/buzz';
import AdUnit from '../Ads/AdUnit';
import ElementsPlayer from '../Connatix/Elements';
import ABeagleContext from '../../contexts/abeagle';
import AmazonShoppingRecommendations from '../AmazonShoppingRecommendations';
import { isBackfillEligible } from '../../utils/connatixVideo';

function InlinePromoUnit(props) {
  const { adPos } = props.config;
  const buzz = useContext(BuzzContext);
  const adsContext = useContext(AdsContext);
  const userCountry = adsContext?.env?.userCountry;
  const showElements = isBackfillEligible(buzz);
  const [amazonRecommendationValue, setAmazonRecommendationValue] =
    useState('control');
  const { experiments, getExperimentValue } = useContext(ABeagleContext);

  useEffect(() => {
    if (experiments && experiments.loaded) {
      setAmazonRecommendationValue(
        getExperimentValue('RT-583-amazon-recommendation-abtest', {
          rejectErrors: false,
        })
      );
    }
  }, [experiments, getExperimentValue]);

  if (!experiments.loaded) {
    return '';
  }

  if (amazonRecommendationValue === 'on' && adPos === 'promo-inline2') {
    return <AmazonShoppingRecommendations />
  } else if (showElements && adPos === 'promo-inline2' && userCountry === 'jp') {
    return <ElementsPlayer buzz={buzz} />
  } else {
    return <AdUnit {...props} />;
  }
}

export default withErrorBoundary(InlinePromoUnit, {
  onError: captureException,
});
