import React, { useContext, useEffect, useState, useRef } from 'react';
import BuzzContext from '../../../contexts/buzz';
import layoutStyles from './taboola.module.scss';
import { api } from '@buzzfeed/consent';
import { getUserGeoCountry } from '@buzzfeed/bf-utils/lib/localization';

const APAC_COUNTRIES = [
  'IN',
  'PH',
  'SG',
  'ID',
  'MY',
  'PK',
  'HK',
  'TH',
  'KR',
  'BD',
  'VN',
  'LK',
  'TW',
  'NP',
  'KH',
  'BN',
  'PG',
  'MO',
  'LA',
  'BT',
  'TL',
  'MN',
];

const inline_js = `
  window._taboola = window._taboola || [];
  _taboola.push({
    mode: 'alternating-thumbnails-a',
    container: 'taboola-below-article-thumbnails',
    placement: 'Below Article Thumbnails',
    target_type: 'mix'
  });
`;

const inline_end_body_js = `
  window._taboola = window._taboola || [];
  _taboola.push({flush: true});
`;

const inline_head_js = `
  window._taboola = window._taboola || [];
  _taboola.push({article:'auto'});
  !function (e, f, u, i) {
    if (!document.getElementById(i)){
      e.async = 1;
      e.src = u;
      e.id = i;
      f.parentNode.insertBefore(e, f);
    }
  }(document.createElement('script'),
  document.getElementsByTagName('script')[0],
  '//cdn.taboola.com/libtrc/buzzfeed-international/loader.js',
  'tb_loader_script');
  if(window.performance && typeof window.performance.mark == 'function')
    {window.performance.mark('tbl_ic');}
`;

function TaboolaAPAC() {
  const [userConsentCompleted, setUserConsentCompleted] = useState();
  const [listenerOn, setListenerOn] = useState();
  const refElement = useRef(null);

  const { classification, flags, isPreview, tags } = useContext(BuzzContext);

  const isEnabled =
    !flags.sensitive &&
    !flags.nsfw &&
    !flags.ad &&
    !tags.includes('--noads') &&
    !isPreview &&
    classification.edition !== 'Japan' &&
    APAC_COUNTRIES.indexOf(getUserGeoCountry()) !== -1;

  useEffect(() => {
    if (!isEnabled) {
      return;
    }
    api.getTCData()
      .then(({ tcData }) => {
        const { gdprApplies, eventStatus } = tcData;
        if (
          (!gdprApplies || ['useractioncomplete', 'tcloaded'].includes(eventStatus)) &&
          !document.getElementById('tb_loader_script')
        ) {
          const headScript = document.createElement('script');
          headScript.type = 'text/javascript';
          headScript.innerHTML = inline_head_js;
          document.head.appendChild(headScript);

          const inlineBodyScript = document.createElement('script');
          inlineBodyScript.type = 'text/javascript';
          inlineBodyScript.innerHTML = inline_js;
          refElement.current.appendChild(inlineBodyScript);

          const bodyScript = document.createElement('script');
          bodyScript.type = 'text/javascript';
          bodyScript.innerHTML = inline_end_body_js;
          document.body.appendChild(bodyScript);

          if (!userConsentCompleted) {
            setUserConsentCompleted(true);
          }
        } else if (!listenerOn && !userConsentCompleted) {
          api.setTCFListener(({ tcData }) => {
            setUserConsentCompleted(tcData.eventStatus === "useractioncomplete" || tcData.eventStatus === 'tcloaded');
          });
          setListenerOn(true);
        }
      });
  }, [listenerOn, userConsentCompleted]);

  return (isEnabled &&
    <div className={layoutStyles.taboolaWrapper} ref={refElement}>
      <div id="taboola-below-article-thumbnails"></div>
    </div>
  );
}

export default TaboolaAPAC;
